
@media (max-width: 767.98px) { 
  .fw {flex-direction:column; &__left {display: none; height: calc(100vh - 30px);}
    &__right {width: 100%; transform: translateY(-50%); position: relative; top: 50%;} 
  } 
  .navbar-nav {flex-direction: row; background-color: #FFF; 
    .nav-item {padding: 3px 10px; font-size: 0.9em; } 
  } 
  .navbar-nav .nav-item.active .nav-link::after {bottom: -4px; } 
  .fixed-top{
    &.navbar-expand-lg{
      .navbar-collapse {position: fixed; top: 65px; width: 100%; border-bottom: 1px solid #DDD; left: 0; background: #fff; padding: 15px; transition: .5s ease all; height: 0 !important;
        &.show{ height: auto !important; }
        .navbar-nav {display: block;

        }
        .dropdown-menu {right: auto; left: 0; }
        .ml-5{margin-left: 0rem!important; }
        .userinfo{ margin-right: 0; justify-content: flex-start;}
      } 
    }
  }
  .hide-mobile {display: none; } 
  .avtar {margin-right: 0; } 
  .userinfo {min-width: auto; } 
  .dropdown-menu {right: 0; left: auto; } 
  .offset {/*margin-top: 112px;*/ } 
  .table-scroll {margin: 1em 0; min-width: 300px; 
    tbody tr {display: block; margin-bottom: 25px; border:1px solid #DDD; } 
      th {display: none; } 
      td {display: block; /*padding-left: 40%;*/ position: relative; /*text-align: right;*/ width: 100%; 
        &.text-right{ text-align: left !important; } 
        &:first-child {padding-top: .5em; font-weight: 600; } 
        &:last-child {padding-bottom: .5em; padding-right: 10px; } 
    }
    td.text-right .text-center{ text-align: left !important;}
  }
  
  th, td {text-align: left;
    @media (min-width: $breakpoint-alpha) {
      display: table-cell; padding: .25em .5em;      
      &:first-child {/*padding-left: 0; font-weight: 600;*/ }      
      &:last-child {padding-right: 0; }
    }
  }
  
  .sml-input {
    float: none; width: 100px; display: inline-block; } 
    .date-scroll i {margin-right: 10px; margin-left: 0; } 
    .border-mobile {padding-bottom: 10px; box-shadow: 0 2px 4px rgba(0,0,0,0.2); }
  .table-scroll {
    /*height: calc(100vh - 247px);*/
    tfoot {
      td {text-align: right; }
    }
  }
  .full-last-td {
    td:last-child {padding-left:10px; }
  }
  .full-first-td {
     td:first-child {padding-left:0.5em; text-align: left !important; }
    tbody th:first-child {display:block !important; font-weight:600 !important; color:#223d66;
    }
  }
  .tx-btn {flex-direction: column;
    a {margin: 0; }
  }
  .scroller table {min-width: auto; }
  
  .no-sticky-footer {
    tfoot {position:relative }
  }
  .item-table {
    tr {border-bottom: 1px solid #DDD; font-size: 0.9em;
      td {border:none;
        &:first-child {min-width: 180px;
        }
      }
      th {border:none;
        &:first-child {min-width: 180px; }
      }      
    }
    tfoot td:first-child {min-width: 180px; }
    .sml-input {width: 80px; margin-right: 20px; float: none; text-align: right; }
  } 
  footer{ padding: 0; }

  .purchase_date{
  .col-md-9{ padding-right:15px !important }
  .col-md-6:first-child{ padding-right:17px !important }
  .col-md-6:last-child{ padding-left:17px !important }
  input[type="text"]{border-radius: 4px !important}
  }
  .label_adjust {
    .form-control{padding: 0.375rem 0.5rem;
      font-size: .9rem;}
  }
  .btn-default.add_option, .btn-default.add_print{ color: #333 !important; font-size: 11px;}
}


@media (min-width: 1100px) {
  .modal-lg {max-width: 1024px !important;}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-nav .nav-item {padding: 15px 0px;}
}

@media (max-width: 1199.98px) {
  .heading {font-size: 20px;}
  .table-chart th:first-child, .table-chart td:first-child{width: 220px; max-width: 220px; min-width: 220px;}
  .sub-menu li a{font-size: 20px;}
}

@media (max-width: 991.98px) {
  .fixed-top.navbar-expand-lg .navbar-collapse{ background-color: #fff; border-bottom: 1px solid #DDD;     position: fixed; top: 65px; left: 0; width: 100%;
    .ml-5{ margin-left: 0 !important;}
    .navbar-nav .nav-item { padding: 5px 10px !important; }
    .userinfo{    justify-content: start; margin-right: 0; margin-left: 15px !important;}
  }
  .ac-header{
    &>.d-flex{ display: block !important;
      &>.d-flex{
        div{
          &:first-child{ width: 120px; }
          &:last-child{ width: calc(100% - 120px); }
        }
      }
    }
    .form-search.mx-4{ margin-right: 0 !important; }
    .av-filter {width: calc(100% - 0px); margin-top: 20px; text-align: left !important;
    /*white-space: nowrap; overflow-x: auto;*/ padding-bottom: 10px;
      .btn-group{ /*max-width: 150px;*/ margin-bottom: 10px;
        >div{ margin: 0 5% 0 0;}
      }
    }
  }
  .heading {font-size: 16px;}
  label{
    &.small{ font-size: 70%; }
  }
  .btn-clear{ font-size: 70%; padding-top: 5px;}
  .sub-menu{
    li{ margin-right: 10px;
      a{font-size: 16px;
        &.active{font-size: 20px;}
      }
    }
  }
  .add_option{
    span{ display: none; }
  }
  /*#navbarSupportedContent{ display: block !important; background: #fff;
    .navbar-nav{
      &.ml-5{ margin-left: 0 !important; }
      .nav-item {padding: 5px 0px; }
    }
    .userinfo{
      &.ml-auto{justify-content: start;}
    }
  }*/
  .side-filters{ z-index: 100 !important;position: fixed; height: calc(100vh - 65px); padding: 10px 15px; left: -230px; top: 65px; bottom: auto; width: 230px; background: #fff; z-index: 4; box-shadow: 0 2px 5px 0 rgba(0,0,0,.15); transition: all .25s ease;
    
    &:before{ content:'⇢'; position: absolute; right: -30px; top: 55px; padding: 5px 10px; border-radius: 0 4px 4px 0; background-color: #fff; color: #212121; box-shadow: 2px 0 3px 0 rgba(0,0,0,.15); cursor: pointer;      
    }
    &:hover{ left: 0;
      &:before{content:'⇠';}
    }
    &.side-reports{ width: 300px; left: -300px;
      &:hover{ left: 0;}
    }
  }
  .mobile_full1{
    tr{ display: block; margin:10px 0; border-bottom: 1px solid #ddd ; min-height: 110px;
      th{ width: 100%; display: block;  padding:5px 0 0; border-top: none;}
      td{ width: calc(50% - 15px); display: block; float: left; border-top: none; padding: 0;
        &:last-child{ width: 30px; text-align: right !important;}
        .trash{ margin-top: 14px !important; }
        input{ width: calc(100% - 15px) !important; }
      }
    }
  }
  .col-lg-5 > .label_adjust{margin-top:-10px}
}


@media (min-width: 768px) {
  .mobile_full1, .mobile_full{
    .w-100{ width: 100%; }
    .d-md-block {
        display: inline-block!important;
    }
  }
  .addnow1{
    .d-md-block {
        display: inline-block!important;
    }
  }
}

@media (max-width: 767px) {
  .modal-body, .modal-header, .modal-footer{ padding: 1rem;}
  .sub-menu{ margin-bottom: 20px; }
  .add_option{position: fixed; right: 15px; margin-right: 0 !important; bottom: 15px; z-index: 8; width: 48px; height: 48px; padding: 0; line-height: 48px; border-radius: 100%; box-shadow: 5px 5px 20px 0px rgba(68, 79, 229, .6);}
  .add_print{position: fixed; right: 15px; bottom: 75px; z-index: 8; width: 48px; height: 48px; padding: 0; line-height: 48px; border-radius: 100%; box-shadow: 5px 5px 20px 0px rgba(68, 79, 229, .6);  }
  .form-search .form-control{ border-radius: 4px; }
  td[data-th]{ padding-top: 35px !important;
    &:before {content: attr(data-th) " "; font-weight: bold; position: absolute; left: 10px; font-size: 11px; color: #666; text-transform: uppercase; letter-spacing: 1px; display: inline-block; top: 10px;
    }
  }
  .authentication_area{
    &.fw{height: calc(100vh - 50px);}
  }
  .fix_row{
    span{ font-size: 13px; }
  }
  .table-chart th:first-child, .table-chart td:first-child{padding-left: 15px;}
  .mobile_nocard{
    .d-card{ padding: 0; border-radius: 0; border: 0; box-shadow: none; /*border-bottom: 1px solid #ddd; padding-bottom: 10px;*/ margin-bottom: 40px !important;
      h4{border-bottom: 1px solid #ddd; padding-bottom: 10px; margin-bottom: 20px;}
    }
    .table{ margin-bottom: 0 !important; }
    h4{
      &.mt-5{ margin-top: 20px !important; }
    }
  }
  .timeline { border-top: 1px solid #ddd; margin-top:10px !important; padding-top: 20px; margin-bottom: 50px; }
  .mobile_full{ 
    tr{position: relative;}
    th, td{ display: block; width: 100% !important;
      input{ width: 100% !important; }
    }
    .trash{ position: absolute; right: 0; top: -7px; }
  }
  .ac-header{
    .av-filter {
      .btn-group{ width: 33%; min-width: 150px;}
    }
  }
  .scroller .kit-row td:first-child {padding-left: 30px; font-size: 14px;}
  input[type="date"]{padding: .375rem .75rem;}
  .fixed-top{
    .navbar-brand{ width: 40%; max-width: 180px;
      //img{ width: 100%; }
    }
    &.navbar-expand{
      .navbar-collapse {position: relative; top: 0;
        .avtar {height: 36px; width: 36px; line-height: 36px;}
        .ml-5{margin-left: 0rem!important; }
        .userinfo {margin-right: 15px;}
      }
    }
  }
  body{
    .mobilehide{ display: none !important; }
    .mobilehide_heading{ padding-top: 10px !important;
      &:before{ display: none !important; }
    }
  }
  th:first-child {
    position: relative;}
  .po-mobileview{
    margin-top: -20px;
  }
  .kit-filter .btn-group{ width: 100%;}
  .kit-filter .hospital-filter{ width: 100% !important;}

  .booking-dropdown {
    margin-bottom: 15px !important;
    max-width: 100% !important;
 }
}

@media (min-width: 768px) and (max-width: 991.98px) { 
  .kit-filter .btn-group{ width: 50%;}
  .kit-filter .hospital-filter{ width: 70.5% !important;}
}

@media (min-width: 776px) {
  .desktophide{display: none}
}

@media (min-width: 576px) {
  .label_adjust{margin-top:-25px}
}
@media (max-width: 575.98px) {  
  .label_adjust{margin-top:10px}
  .col-10 .label_adjust{margin-top:-25px}
  .col-md-4 > .label_adjust, .col-md-4 > .label_adjust .label_adjust{ max-width: 100%;}
  .col-md-4 > .label_adjust .mx-3{ margin: 20px 0 0 !important}
  .custom_fltr .filter_section{ width: 350px;}
  .ac-header .av-filter .btn-group{ width: 50%;}
  .lable-field-position{
    float: left !important;
  }
  .dashboarRightContent{
    float: left !important;
  }
  .datePicker-mr{
    margin: 0px -4px;
  }
}

@media (max-width: 980px) {
  .loading-state {
    width: 100%;
  }
  .loading-state-new {
    width: 100% !important;
  }
  .text-manage{
    text-align: left;
  }
  .booking-dropdown {
    &.open {
    margin-bottom: 30px !important;
    max-width: 100% !important;
  }
 }
}

@media (min-width: 1201px) and (max-width: 1315px) { 
  .sub-menu li {
    margin-right: 9px;
  }
  .sub-menu li a.active {
    font-size: 20px;
  }
}
